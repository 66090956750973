import http from '../lib/http'
import judgStatus from './base'
const ROOMURL = '/back/getAllRoom'
const BUILDINGURL = '/back/getAllBuilding'
const FLOORURL = '/back/getAllFloor'
const GETURL = '/back/getFloorRoom'
const RESTURL = '/back/addFloorRoom'
const EDITURL = '/back/editFloorRoom'
const DELURL = '/back/delFloorRoom'
const ONEURL = '/back/getFloorRoomInfo'
const API = {
    getAllRoom(data) {
        return new Promise((resolve, reject) => {
            http.post(ROOMURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getAllBuilding(data) {
        return new Promise((resolve, reject) => {
            http.post(BUILDINGURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getAllFloor(data) {
        return new Promise((resolve, reject) => {
            http.post(FLOORURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    create(data) {
        return new Promise((resolve, reject) => {
            http.post(RESTURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    update(data) {
        return new Promise((resolve, reject) => {
            http.post(EDITURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    del(data) {
        return new Promise((resolve, reject) => {
            http.post(DELURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    query(data) {
        return new Promise((resolve, reject) => {
            http.post(GETURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    one(data) {
        return new Promise((resolve, reject) => {
            http.post(ONEURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
}
export default API