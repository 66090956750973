//科贸 各个校区公寓配置
export const setBuildText = ((text,array)=>{
    let textArray = []
    if(text == '清远校区'){
        array.forEach((item)=>{
            if (item == "全部") {
                textArray.push({
                    name:item,
                    id:item
                })
            } else if (item == 100) {
                textArray.push({
                    name:"教师公寓",
                    id:item
                })
            } else if (item == 101) {
                textArray.push({
                    name:"培训大楼",
                    id:item
                })
            } else {
                textArray.push({
                    name:item + "幢",
                    id:item
                })
            }
        })
    }else if(text == '白云校区'){
        array.forEach((item)=>{
            textArray.push({
                name:'A'+item+'栋',
                id:item
            })
        })
    }else if(text == '天河校区'){
        array.forEach((item)=>{
            textArray.push({
                name:item+'栋',
                id:item
            })
        })
    }
    return textArray
})

//科贸 各个校区楼层配置
export const setCengText = ((text,dong,array)=>{
    //学校 公寓 数组
    let textArray = []
    if(text == '清远校区'){
        array.forEach((item)=>{
            textArray.push({
                id:item.toString(),
                name:item
            })
        })
    }else if(text == '白云校区'){
        array.forEach((item)=>{
            if(item == 0){
                textArray.push({
                    id:item.toString(),
                    name:'首层'
                })
            }else{
                textArray.push({
                    id:item.toString(),
                    name:item
                })
            }
        })
    }else if(text == '天河校区'){
        array.forEach((item)=>{
            textArray.push({
                id:item.toString(),
                name:item
            })
        })
    }
    return textArray
})

//科贸 各个校区房间配置
export const setRoomText = ((text,dong,ceng,array)=>{
    //学校 公寓 楼层 数组
    let textArray = []
    if(text == '清远校区' || text == ''){
        array.forEach((item)=>{
            item.name = item.room_no
        })
        textArray = array
    }else if(text == '白云校区'){
        array.forEach((item)=>{
            item.name = item.room_no
        })
        textArray = array
    }else if(text == '天河校区'){
        if(dong == 3 ){
            array.forEach((item)=>{
                if(Number(item.room_no) == 110){
                    item.name = '附'+ceng+'01'
                }else if(Number(item.room_no) == 111){
                    item.name = '附'+ceng+'02'
                }else if(Number(item.room_no) == 112){
                    item.name = '附'+ceng+'03'
                }else if(Number(item.room_no) == 210){
                    item.name = '附'+ceng+'01'
                }else if(Number(item.room_no) == 211){
                    item.name = '附'+ceng+'02'
                }else if(Number(item.room_no) == 212){
                    item.name = '附'+ceng+'03'
                }else if(Number(item.room_no) == 310){
                    item.name = '附'+ceng+'01'
                }else if(Number(item.room_no) == 311){
                    item.name = '附'+ceng+'02'
                }else if(Number(item.room_no) == 312){
                    item.name = '附'+ceng+'03'
                }else if(Number(item.room_no) == 410){
                    item.name = '附'+ceng+'01'
                }else if(Number(item.room_no) == 411){
                    item.name = '附'+ceng+'02'
                }else if(Number(item.room_no) == 412){
                    item.name = '附'+ceng+'03'
                }else if(Number(item.room_no) == 510){
                    item.name = '附'+ceng+'01'
                }else if(Number(item.room_no) == 511){
                    item.name = '附'+ceng+'02'
                }else if(Number(item.room_no) == 512){
                    item.name = '附'+ceng+'03'
                }else{
                    item.name = Number(item.room_no)
                }
            })
        }else{
            array.forEach((item)=>{
                item.name = Number(item.room_no)
            })
        }
        textArray = array 
    }
    return textArray
})