import http from '../lib/http'
import judgStatus from './base'
const RESTURL = '/back/getStatistics'
const USERURL = '/back/getUserStatistics'
const API = {
    getStatistics(statis) {
        return new Promise((resolve, reject) => {
            http.post(RESTURL, statis).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getUserStatistics(userStatis) {
        return new Promise((resolve, reject) => {
            http.post(USERURL, userStatis).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
}
export default API
