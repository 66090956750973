import http from '../lib/http'
import judgStatus from './base'
const RESTURL = '/back/getCampus'
const GETBRANDSURL = '/back/getBrands'
const ADDBRANDSURL = '/back/addBrand'
const EDITBRANDSURL = '/back/editBrand'
const GETBRANDINFOURL = '/back/getBrandInfo'
const DELBRANDURL = '/back/delBrand'
const GETCAMPUSINFOURL ='/back/getCampusInfo'
const EDITCONTENTURL = '/back/editContent'

const httpCampus = {
    //获取校区列表
    getCampusList(data) {
        return new Promise((resolve, reject) => {
            http.post(RESTURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },

    //获取校区详情
    getCampusDetail(data) {
        return new Promise((resolve, reject) => {
            http.post(GETCAMPUSINFOURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },

    //添加公告
    postAddContent(data) {
        return new Promise((resolve, reject) => {
            http.post(EDITCONTENTURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },


    //获取品牌列表
    getBrands(data){
        return new Promise((resolve, reject) => {
            http.post(GETBRANDSURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //获取品牌详情
    getBrandInfo(){
        return new Promise((resolve, reject) => {
            http.post(GETBRANDINFOURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    
    //新增品牌
    addBrand(data){
        return new Promise((resolve, reject) => {
            http.post(ADDBRANDSURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //编辑品牌
    editBrand(data){
        return new Promise((resolve, reject) => {
            http.post(EDITBRANDSURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //删除品牌
    delBrand(data){
        return new Promise((resolve, reject) => {
            http.post(DELBRANDURL,data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    }
    
    
}
export default httpCampus
