<template>
  <!-- <div
    :style="{ 'margin-top': '-40px', transform: 'translateY(' + top + 'px)' }"
  >
    <div
      class="loading-box"
      v-if="!isRefresh"
      :style="{
        color: '#969799',
        'font-size': '14px',
        margin: '10px 0',
      }"
    >
      <van-icon
        name="down"
        :style="{
          'margin-right': '10px',
          transform: flag ? 'rotate(180deg)' : '',
        }"
      />
      <span>{{ loadingText }}</span>
    </div>
    <van-loading v-else style="padding: 15px 0">{{ loadingText }}</van-loading>
    <div
      class="data-statis-box"
      style="width: 100%; height: 100vh"
      @touchstart="touchStart($event)"
      @touchmove="touchMove($event)"
      @touchend="touchEnd($event, searchStatis, resetQueryParams)"
    > -->
  <div class="data-statis-box" style="width: 100%; height: 100vh">
    <div class="date-box">
      <span class="select-data" @click="showBottom = true">{{
        dateValue
      }}</span>
      <span class="select-data" @click="showBottom2 = true">{{
        dateValue2
      }}</span>
    </div>
    
    <div style="padding: 10px 0;margin-bottom: 20px;">
      <div  class="item d-flex align-center justify-around">
        <div class="d-flex justify-center align-center flex-column "
          style="width:140px;"
        >
          <span>校区</span>
          <van-popover
            v-model:show="showCampusPopover"
            placement="bottom-start"
          >
            <van-grid
              square
              clickable
              :border="false"
              column-num="3"
              style="width: 240px; padding: 10px; box-sizing: border-box"
            >
              <van-grid-item
                v-for="item in campusList"
                :key="item"
                @click="selectCampusFun(item)"
              >
                {{ item.campus_name }}
              </van-grid-item>
            </van-grid>
            <template #reference>
              <van-button type="default" size="mini">{{
                selectCampus == "请选择" ? selectCampus : selectCampus
              }}</van-button>
            </template>
          </van-popover>
        </div>
        <div class="right-box">
          <div>{{ campus_name_user }}</div>
        </div>
      </div>
    </div>
    <div class="statis-body-content">
      <div class="building-layer-box">
        <div class="item d-flex align-center justify-around">
          <div class="d-flex justify-center align-center flex-1 flex-column">
            <span class="span-title">幢</span>
            <van-popover
              v-model:show="showBuildingPopover"
              placement="bottom-start"
              @open="openBuildingList"
            >
              <van-grid
                square
                clickable
                :border="false"
                column-num="3"
                style="width: 240px; padding: 10px; box-sizing: border-box"
              >
                <van-grid-item
                  v-for="item in buildingList"
                  :key="item"
                  @click="showBuilding(item)"
                  >{{ item.name }}</van-grid-item
                >
              </van-grid>
              <template #reference>
                <van-button type="default" size="mini">{{
                  showBuildingNum == "请选择" ? "请选择" : showBuildingNum
                }}</van-button>
              </template>
            </van-popover>
          </div>
          <div class="scroll-right">
            <p>{{ showBuildingNum == "请选择" ? "请选择" : showBuildingNum }}</p>
          </div>
        </div>
        <div class="item d-flex align-center justify-around">
          <div class="d-flex justify-center align-center flex-1 flex-column">
            <span class="span-title">层</span>
            <van-popover
              v-model:show="showLayerPopover"
              placement="bottom-start"
              @open="openLayer"
            >
              <van-grid
                square
                clickable
                :border="false"
                column-num="3"
                style="width: 240px; padding: 10px; box-sizing: border-box"
              >
                <van-grid-item
                  v-for="item in FloorList"
                  :key="item"
                  :text="item.name"
                  @click="showLayer(item)"
                >{{ item.name }}</van-grid-item
                >
              </van-grid>
              <template #reference>
                <van-button type="default" size="mini">{{
                  showLayerNum == "请选择" ? "请选择" : showLayerNum > 0 ? showLayerNum + "层" :showLayerNum
                }}</van-button>
              </template>
            </van-popover>
          </div>
          <div class="scroll-right">
            <p>{{ showLayerNum == "请选择" ? "请选择" : showLayerNum > 0 ? showLayerNum + "层" :showLayerNum }}</p>
          </div>
        </div>
        <div class="item d-flex align-center justify-around">
          <div class="d-flex justify-center align-center flex-1 flex-column">
            <span class="span-title">房间</span>
            <van-popover
              v-model:show="showRoomPopover"
              placement="bottom-start"
              @open="openRoom"
            >
              <van-grid
                square
                clickable
                :border="false"
                column-num="3"
                style="
                    width: 240px;
                    padding: 10px;
                    box-sizing: border-box;
                    max-height: 200px;
                    overflow-y: auto;
                  "
              >
                <van-empty
                  image="search"
                  v-if="RoomList.length == 0"
                  description="没有该房间号"
                />
                <van-grid-item
                  v-for="item in RoomList"
                  :key="item"
                  :text="item.room_no"
                  @click="showRoom(item.room_no)"
                >{{ item.name }}
                </van-grid-item>
              </van-grid>
              <template #reference>
                <!-- <van-button type="default" size="mini">{{
                  showRoomNum == 0 ? "请选择" : showRoomNum + " 房间"
                }}</van-button> -->
                <van-field
                  style="padding: 0"
                  v-model="searchRoomValue"
                  @update:model-value="searchRoomNum"
                  :disabled="showLayerNum == '请选择'"
                  placeholder="选择房间"
                />
              </template>
            </van-popover>
          </div>
          <div class="scroll-right">
            <p>{{ searchRoomValue }}</p>
          </div>
        </div>

      </div>
    </div>
    <div style="width:100%;display: flex;flex-direction: row;align-items: center;">
      <p style="width: 110px;">品牌数量</p>
      <div class="scroll-right" >
        <div :style="{width:dongShopNum*150+'px'}">
          <div class="scroll-right-item" v-for="item in allShopData" :key="item">
            <p>{{ item.name }}</p>
            <p>{{ item.value }}桶</p>
          </div>
        </div>
      </div>
    </div>
    
    <van-popup
      v-model:show="showBottom"
      position="bottom"
      :style="{ height: '47%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate"
        @cancel="showBottom = false"
      />
    </van-popup>
    <van-popup
      v-model:show="showBottom2"
      position="bottom"
      :style="{ height: '47%' }"
    >
      <van-datetime-picker
        v-model="currentDate2"
        type="date"
        title="选择日期"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate2"
        @cancel="showBottom2 = false"
      />
    </van-popup>
  </div>
  <!-- </div> -->
</template>

<script>
import { ref, reactive, computed } from "vue";
import {
  Button,
  Grid,
  GridItem,
  Popover,
  Toast,
  Field,
  Empty,
  Popup,
  DatetimePicker,
  // Loading,
  // Icon,
} from "vant";
import api from "../../../api/statistics";
import bfr from "../../../api/room";
import refresh from "../../../state/refresh-data";
import httpCampus from "../../../api/getCampus";
import { setBuildText,setCengText,setRoomText } from '../../../lib/setBuild'


export default {
  name: "Statis",
  components: {
    "van-button": Button,
    "van-grid": Grid,
    "van-grid-item": GridItem,
    "van-popover": Popover,
    "van-field": Field,
    "van-empty": Empty,
    "van-popup": Popup,
    "van-datetime-picker": DatetimePicker,
    // "van-loading": Loading,
    // "van-icon": Icon,
  },
  setup() {
    //   tabbar 栏
    const onChange = (index) => {
      // console.log(index);
    };
    // 搜索房间号
    const searchRoomValue = ref("");
    const searchRoomNum = (value) => {
      const arr = [];
      if (!value) {
        RoomList.value = oldRoomList.value;
      } else {
        oldRoomList.value.forEach((el) => {
          if (el.room_no.indexOf(value) != -1) {
            arr.push(el);
          }
        });
        RoomList.value = arr;
      }
    };

    // 搜索参数
    let queryParams = reactive({
      start_time: "",
      end_time: "",
      user_id: "",
      building_no: "",
      floor_no: "",
      room_no: "",
      campus_id:"",
    });
    // 选择日期
    const date = ref("");
    const show = ref(false);
    const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    
    // 搜索框的值
    const value = ref("");

    // 搜索函数
    const yuanShopData = ref([]);//员工品牌数据
    const dongShopData = ref([]);//栋数品牌数据
    const dongShopNum = ref(0);
    const cengShopData = ref([]);//层数品牌数据
    const roomShopData = ref([]);//房间数品牌数据
    const allShopData = ref([])
    const searchStatis = async () => {
      try {
        const res = await api.getStatistics(queryParams);
        if (res.code == 200) {
          isRefresh.value = false;
          loadingText.value = "下拉刷新";
          top.value = 0;
          flag.value = false;
          //桶数
          allShopData.value = []
          res.data.brandList.forEach((item)=>{
            allShopData.value.push({
              name:item.brand_name,
              value:item.brandNum
            })
          })
          dongShopNum.value = res.data.brandList.length
          return res.data;
        }
      } catch (error) {
        Toast("搜索出错");
      }
    };

    //校区
    const showCampusPopover = ref(false)
    const campusList = ref([])
    const campus_id =ref('')
    const selectCampus = ref('请选择')
    const campus_name_user = ref('')
    const getCampusListFun = async()=>{
      const res = await httpCampus.getCampusList();
      if(res.code == 200){
        campusList.value = res.data;
        // getStatistics()
      }
    }
    getCampusListFun()
    // 选择 校区
    const selectCampusFun = async (item) => {
      selectCampus.value = item.campus_name;
      queryParams.campus_id = item.campus_id;
      campus_name_user.value =item.campus_name
      showCampusPopover.value = false;

      queryParams.value = reactive({
        building_no: "",
        floor_no: "",
        room_no: "",
      });
      showBuildingNum.value = "请选择";
      showLayerNum.value = "请选择";
      showRoomNum.value = 0;
      searchRoomValue.value = "";
      getBuildingList();
      searchStatis();
    };


    // 栋数 气泡弹窗、选择栋数、栋统计
    const showBuildingPopover = ref(false);
    const showBuildingNum = ref("请选择");
    const showBuildingStatis = reactive({
      jtCount: 0,
      nfCount: 0,
    });
    const showBuilding = async (item) => {
      queryParams.building_no = item == "全部" ? "" : item.id;
      showBuildingNum.value = item == "全部" ? 0 : item.name;
      showBuildingPopover.value = false;
      searchStatis();
      getFloorList();
    };
    const openBuildingList = () =>{
      console.log(queryParams.campus_id)
      if (queryParams.campus_id == "") {
        Toast("请先选择校区");
        showBuildingPopover.value = false;
      }
    }


    // 层数 气泡弹窗、选择层数
    const showLayerPopover = ref(false);
    const showLayerNum = ref("请选择");
    const showLayerStatis = reactive({
      jtCount: 0,
      nfCount: 0,
    });
    const showLayer = async (item) => {
      queryParams.floor_no = item == "全部" ? "" : item.id;
      showLayerNum.value = item == "全部" ? 0 : item.name;
      showLayerPopover.value = false;
      searchStatis()
      getRoomList();
    };
    const openLayer = () => {
      if (showBuildingNum.value == "请选择") {
        Toast("请先选择幢数");
        showLayerPopover.value = false;
      }
    };
    // 房间号 气泡弹窗、选择房间号
    const showRoomPopover = ref(false);
    const showRoomNum = ref(0);
    const showRoomStatis = reactive({
      jtCount: 0,
      nfCount: 0,
    });
    const showRoom = async (item) => {
      queryParams.room_no = item == "全部" ? "" : item;
      showRoomNum.value = item == "全部" ? 0 : item;
      showRoomPopover.value = false;
      searchRoomValue.value = item == "全部" ? "" : item;
      searchStatis();
    };
    const openRoom = () => {
      if (showLayerNum.value == "请选择") {
        Toast("请先选择层数");
        showRoomPopover.value = false;
      }
    };

    // 数据总数
    const dataCount = reactive({
      jtCount: "",
      nfCount: "",
      jt_name: "",
      nf_name: "",
    });

    // 获取总统计信息
    const getStatistics = async () => {
      try {
        const res = await api.getStatistics({
          campus_id:queryParams.campus_id
        });
        if (res.code == 200) {
          dataCount.jtCount = res.data.jtCount;
          dataCount.nfCount = res.data.nfCount;
          dataCount.nf_name = res.data.nf_name;
          dataCount.jt_name = res.data.jt_name;
        }
      } catch (error) {
        Toast("获取统计信息出错");
      }
    };

    // 获取所有栋数
    const buildingList = ref([]);
    const getBuildingList = async () => {
      try {
        const res = await bfr.getAllBuilding({
          campus_id:queryParams.campus_id
        });
        if (res.code == 200) {
          buildingList.value = setBuildText(selectCampus.value,res.data);
          buildingList.value.unshift({
            name:'全部',
            value:''
          })
        }
      } catch (error) {
        Toast("获取栋数出错");
      }
    };
    // 获取所有层数
    const FloorList = ref([]);
    const getFloorList = async () => {
      try {
        const res = await bfr.getAllFloor({
          building_no: queryParams.building_no,
          campus_id:queryParams.campus_id
        });
        if (res.code == 200) {
          FloorList.value = setCengText(selectCampus.value,queryParams.building_no,res.data);
          FloorList.value.unshift({
            name:'全部',
            value:''
          })
        }
      } catch (error) {
        Toast("获取层数出错");
      }
    };

    // 获取所有房间数
    const RoomList = ref([]);
    const oldRoomList = ref([]);
    const getRoomList = async () => {
      try {
        const res = await bfr.getAllRoom({
          building_no: queryParams.building_no,
          floor_no: queryParams.floor_no,
          campus_id:queryParams.campus_id,
        });
        if (res.code == 200) {
          RoomList.value = setRoomText(selectCampus.value,queryParams.building_no,queryParams.floor_no,res.data)
          RoomList.value.unshift({ room_no: "全部" ,name:"全部"})
        }
      } catch (error) {
        Toast("获取房间数出错");
      }
    };

    // 开始日期
    const currentDate = ref(new Date());
    const showBottom = ref(false);
    const dateValue = ref("开始日期");
    const confirmDate = async (val) => {
      const y = val.getFullYear();
      const m = val.getMonth() + 1;
      const d = val.getDate();
      const formatDate = y + "-" + m + "-" + d;
      queryParams.start_time = formatDate;
      dateValue.value = formatDate;
      const res = await searchStatis();
      dataCount.nfCount = res.nfCount;
      dataCount.jtCount = res.jtCount;
      dataCount.nf_name = res.nf_name;
      dataCount.jt_name = res.jt_name;
      showBottom.value = false;
    };
    // 结束日期
    const showBottom2 = ref(false);
    const currentDate2 = ref(new Date());
    const dateValue2 = ref("结束日期");
    const confirmDate2 = async (val) => {
      const y = val.getFullYear();
      const m = val.getMonth() + 1;
      const d = val.getDate();
      const date = y + "-" + m + "-" + d;
      queryParams.end_time = date;
      dateValue2.value = date;
      const res = await searchStatis();
      dataCount.nfCount = res.nfCount;
      dataCount.jtCount = res.jtCount;
      dataCount.nf_name = res.nf_name;
      dataCount.jt_name = res.jt_name;
      showBottom2.value = false;
    };

    const resetQueryParams = () => {
      queryParams.start_time = "";
      queryParams.end_time = "";
      queryParams.user_id = "";
      queryParams.building_no = "";
      queryParams.floor_no = "";
      queryParams.room_no = "";
      showBuildingNum.value = "请选择";
      showLayerNum.value = "请选择";
      searchRoomValue.value = "";
      dateValue.value = "开始时间";
      dateValue2.value = "结束时间";
    };

    // 刷新
    const {
      flag,
      isRefresh,
      loadingText,
      top,
      touchStart,
      touchMove,
      touchEnd,
    } = refresh();
    return {
      // protyper
      date,
      show,
      value,
      showRoomPopover,
      showLayerPopover,
      showBuildingPopover,
      dataCount,
      buildingList,
      showBuildingNum,
      showLayerNum,
      showRoomNum,
      FloorList,
      RoomList,
      showRoomStatis,
      showLayerStatis,
      showBuildingStatis,
      searchRoomValue,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2100, 12, 31),
      currentDate,
      showBottom,
      dateValue,
      showBottom2,
      currentDate2,
      dateValue2,
      flag,
      isRefresh,
      loadingText,
      top,
      showCampusPopover,
      campusList,
      selectCampus,
      campus_name_user,
      dongShopData,
      cengShopData,
      yuanShopData,
      roomShopData,
      dongShopNum,
      allShopData,

      // method
      touchStart,
      touchMove,
      touchEnd,
      onChange,
      showBuilding,
      showRoom,
      showLayer,
      openLayer,
      openRoom,
      searchRoomNum,
      confirmDate,
      confirmDate2,
      resetQueryParams,
      searchStatis,
      selectCampusFun,
      openBuildingList,
    };
  },
};
</script>
<style scoped lang="less">
.tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
}
.date {
  width: 132px;
}
.statis-header {
  border-bottom: 2px solid #ccc;
  padding: 0 20px;
  // margin-bottom: 40px;
}
.statis-body-header {
  font-size: 30px;
  margin-bottom: 40px;
}
.building-layer-box {
  border-bottom: 2px solid #ccc;
  margin-bottom: 40px;
  .item {
    border-top: 2px solid #ccc;
    padding: 20px 0;
    font-size: 30px;
  }
}
.span-title {
  // text-align: left;
  // width: 120px;
  margin-bottom: 5px;
}
.date-box {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  justify-content: space-around;
}
.select-data {
  background-color: white;
  min-width: 100px;
  height: 100px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  max-width: 200px;
}
::v-deep .van-grid-item__content {
  width: 140px;
  height: 52px;
  border: 2px solid #ccc;
  padding: 0;
  margin-left: 10px;
}
::v-deep .van-grid-item {
  padding-top: 80px !important;
}
::v-deep .van-empty {
  margin-left: 40px;
}
::v-deep .van-button {
  font-size: 30px;
}
::v-deep .van-grid-item__text {
  font-size: 30px;
}
::v-deep .van-empty__image {
  width: 200px !important;
  height: 200px !important;
}
::v-deep .van-popover__wrapper {
  flex: 1;
}
::v-deep .van-field__control {
  width: 120px;
  text-align: center;
}
.right-box{
  width: calc(100% - 200px);
}
.scroll-right{
  width: calc(100% - 200px);
  padding: 20px 0;
  min-height: 140px;
  overflow: hidden;
  overflow-x: scroll;
}
.scroll-right-item{
  width: 140px;
  height: 100%;
  float: left;
  display: block;
  margin-right: 60px;
}
.scroll-right-item p {
  margin:0;
  margin-bottom: 20px;
}
.scroll-right-item p:nth-of-type(2) {
  margin:0;
}
</style>
